<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('Select room')"
    style="width: 100%"
    dropdown-class-name="room-max-content-width"
    :default-active-first-option="false"
    :not-found-content="null"
    :size="size"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option
      v-for="d in data"
      :key="d[returnValue]"
      :title="d.name"
      :filter="d.name"
      :data="d"
    >
      {{ d.name }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select room": "Select room"
  },
  "ja": {
    "Select room": "部屋選択"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { cleanObject } from '@/utils/util';

export default {
  name: 'InputRoom',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    returnValue: {
      type: String,
      default: 'id',
    },
    propertyId: {
      type: String,
      default: null,
    },
    roomTypeId: {
      type: Number,
      default: null,
    },
    useRoomType: {
      type: [Boolean, null],
      default: null,
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    allowAll: {
      type: Boolean,
      default() {
        return false;
      },
    },
    cancellable: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      id: undefined,
      data: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (this.returnValue === 'id') {
          this.id = nv ? parseInt(nv, 10) : undefined;
        } else {
          this.id = nv || undefined;
        }
      },
    },
    propertyId: {
      immediate: true,
      handler() {
        this.data = [];
        this.handleSearch().then(() => {
          const exist = this.data.find((room) => room[this.returnValue] === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
    roomTypeId: {
      immediate: true,
      handler() {
        this.data = [];
        this.handleSearch().then(() => {
          const exist = this.data.find((room) => room[this.returnValue] === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      const params = cleanObject({
        propertyId: this.propertyId,
        roomTypeId: this.roomTypeId,
        infinite: true,
        cancellable: this.cancellable,
      });

      if (value.length > 0) {
        params.name = value;
      }

      if (this.useRoomType !== null) {
        params.useRoomType = this.useRoomType;
      }

      return this.$store.dispatch('rooms/names', params).then((res) => {
        this.data = res.list;

        if (this.allowAll) {
          if (this.data.length) {
            const all = { id: 0, name: 'ALL', code: 'all' };
            this.data.push(all);
          } else {
            this.data = [];
            this.id = '';
          }
        }

        if (this.id) {
          const exist = this.data.find((room) => room[this.returnValue] === this.id);

          this.handleChange(this.id, { data: exist });
        }

        return true;
      });
    },
    delaySearch: debounce(function delaySearch(value) {
      this.handleSearch(value);
    }, 600),
    filterOption(input, option) {
      return option.filter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange(value, option) {
      this.id = value;

      if (value !== 0) {
        this.$emit('change', this.id, option.data);
        this.$emit('update:value', this.id);
      } else {
        const roomNos = this.data.map((item) => item.id).filter((id) => id > 0);
        this.$emit('change', this.id, roomNos);
        this.$emit('update:value', this.id, roomNos);
      }
    },
  },
};
</script>

<style>
.room-max-content-width {
  min-width: 250px !important;
}
@media screen and (max-width: 380px) {
  .room-max-content-width {
    min-width: auto !important;
  }
}
</style>
